import type {ReactNode} from 'react'
import {useState, createContext, useEffect, useContext} from 'react'
import type {NextRouter} from 'next/router'
import {useRouter} from 'next/router'
import {setCookie, getCookie} from 'cookies-next'
import Script from 'next/script'
import {Locales} from '@elanco/rebate-components'
import type {IContentItem} from '@kontent-ai/delivery-sdk'
import type {
	AppPermissions,
	CurrentUserContext,
	Data as User,
	Profile,
} from '@/models/CurrentUserContext'
import {getUserCookieExpirationDate} from '@/utils/cookies'
import {VetTowerAppPermissionMapping} from '@/model-as-code/vet/configs/authenticationGate.mac'
import type {AppPermissionMaping as AppPermissionMapping} from '@/model-as-code/allTowers/configs/authenticationGateConstants'
import {PetTowerAppPermissionMapping} from '@/model-as-code/pet/configs/authenticationGate.mac'
import {FarmTowerAppPermissionMapping} from '@/model-as-code/farm/configs/authenticationGate.mac'
import {env} from '@/utils/env/client.mjs'
import type {CdcUser} from '@/models/CdcUserModels'
import {
	areRolesSufficient,
	doesUserHaveSufficientRoles,
	getUserRoles,
} from '@/_new-code/products/gated-content/auth'
import type {
	AdvancedPageTemplateContentItem,
	Block,
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import type {
	AuthNavItemLinkContentItem,
	NavItemLinkContentItem,
} from '@/_new-code/products/flexible-web-toolkit/components/header/models'
import type {UserDetails} from '../../../services/CDCUserService'
import {getAuthenticationRequirements} from './util'
import {AuthenticationGateLevel} from './types'
import {SiteBannerSelfValidationBlock} from './self-banner-self-validation/site-banner-self-validation'

export interface UserCookie {
	id: string
	v: boolean
}

export function logout(router: NextRouter, redirectUrl: string): void {
	window.gigya?.accounts.logout({
		callback: () => {
			setCookie('cdc_user', null)
			void router.push(redirectUrl)
		},
	})
}

const AuthContext = createContext<CurrentUserContext | undefined>(undefined)

export interface Auth {
	isLoggedIn: boolean
	isValidated: boolean
	isSelfValidated: boolean
}

export const useAuth = (): CurrentUserContext => {
	const authContext = useContext(AuthContext)
	if (authContext === undefined) {
		throw new Error('useAuth must be used inside an AuthProvider')
	}
	return authContext
}

interface AuthProviderProps {
	page: Tersed<AdvancedPageTemplateContentItem>
	globalConfig: Tersed<GlobalConfigContentItem>
	auth: Auth
	children: ReactNode
}

export const AuthProvider = ({
	page,
	globalConfig,
	auth,
	children,
}: AuthProviderProps): JSX.Element => {
	// Set initial state from server-side checks
	const [authenticated, setAuthenticated] = useState<boolean>(auth.isLoggedIn)
	const [validated, setValidated] = useState<boolean>(auth.isValidated)
	const [selfValidated, setSelfValidated] = useState<boolean>(
		auth.isSelfValidated
	)
	const [authLevel, setAuthLevel] = useState<number>(-1)
	const [userDetails, setUserDetails] = useState<UserDetails>({
		id: null,
		firstName: '',
		lastName: '',
		emailAddress: '',
		telephone: null,
		practiceName: null,
		practicePostcode: null,
		crmId: null,
		light: null,
		appPermissions: null,
		userRoles: null,
		salutation: '',
	})
	const [ready, setReady] = useState<boolean>(false)
	const router = useRouter()

	const gateLevel = getAuthenticationRequirements(page, globalConfig)

	const {loginUrl, validationErrorUrl, cdcLang} = globalConfig.elements
	const locale = cdcLang || router.locale

	useEffect(() => {
		let level = 0
		if (selfValidated) level = 1
		if (authenticated) level = 2
		if (validated) level = 3
		setAuthLevel(level)
	}, [selfValidated, authenticated, validated])

	const shouldShowPage = (): boolean => {
		switch (gateLevel) {
			case 'Validated':
				return validated
			case 'Authenticated':
				return authenticated || validated
			default:
				return true
		}
	}

	const authenticateUser = (
		isLoggedIn: boolean,
		userHasNecessaryAppPermissions: boolean
	): void => {
		setAuthenticated(isLoggedIn)
		setSelfValidated(auth.isSelfValidated || isLoggedIn)
		setValidated(userHasNecessaryAppPermissions)
		//To add CDC flow builder only for German vet hybris users
		if (
			env.NEXT_PUBLIC_TOWER === 'vet' &&
			isLoggedIn &&
			router.locale === 'de'
		) {
			if (!window.gigya) return

			const flow = window.gigya.flow('vet_hybris_profile_update')
			//start executing the flow
			flow.execute()
		}
		if (
			isLoggedIn &&
			(router.asPath === '/login' || router.asPath === '/register')
		) {
			void router.replace(`/`)
		}
		if (
			!isLoggedIn &&
			(gateLevel === 'Authenticated' || gateLevel === 'Validated')
		) {
			void router.replace(
				`/${router.locale}${loginUrl}?returnUrl=/${router.locale}${router.asPath}`
			)
			return
		}

		if (!userHasNecessaryAppPermissions && gateLevel === 'Validated') {
			const unauthenticatedRedirectUrl =
				page.elements.snippetPageSeoUnauthenticatedRedirectUrl
			if (unauthenticatedRedirectUrl) {
				window.location.href = unauthenticatedRedirectUrl
			} else {
				void router.replace(`/${locale}${validationErrorUrl}`)
			}
		}
	}

	const authenticateUserRole = (userHasNecessaryRoles: boolean): void => {
		setValidated(userHasNecessaryRoles)
		if (!userHasNecessaryRoles && gateLevel === 'Validated') {
			const unauthenticatedRoleRedirectUrl =
				page.elements.snippetPageSeoUnauthenticatedRoleRedirectUrl
			if (unauthenticatedRoleRedirectUrl) {
				window.location.href = unauthenticatedRoleRedirectUrl
			} else {
				void router.replace(`/${locale}${validationErrorUrl}`)
			}
		}
	}

	const loadTowerAndCheckPermissions = (
		pageAppPermissions: string[] | undefined,
		userAppPermissions: AppPermissions | undefined | null
	): boolean => {
		if (!pageAppPermissions || pageAppPermissions.length === 0) {
			return false
		}
		if (!userAppPermissions) {
			return false
		}
		let appPermissionMapping: AppPermissionMapping
		const tower = env.NEXT_PUBLIC_TOWER
		if (tower === 'pet') {
			appPermissionMapping = PetTowerAppPermissionMapping
		} else if (tower === 'farm') {
			appPermissionMapping = FarmTowerAppPermissionMapping
		} else {
			appPermissionMapping = VetTowerAppPermissionMapping
		}
		const mapped = pageAppPermissions.map((codename) => {
			return appPermissionMapping.mapping[codename]
		})
		return mapped.every(
			(appPermissionField) =>
				userAppPermissions[
					appPermissionField as keyof typeof userAppPermissions
				]
					?.toString()
					?.toLowerCase() === 'true'
		)
	}

	const doesUserHaveNecessaryAppPermission = (
		userAppPermissions: AppPermissions | undefined
	): boolean => {
		if (gateLevel !== 'Validated') {
			return false
		}
		const pageAppPermissions =
			page.elements.authenticationGateApplicationPermissions.map(
				(a) => a.codename
			)
		const pageRoles = page.elements.authenticationGateUserRoles.map(
			(a) => a.codename
		)
		if (pageAppPermissions.length === 0 && pageRoles.length === 0) {
			return false
		}
		if (pageAppPermissions.length === 0 && pageRoles.length >= 0) {
			return true
		}

		return loadTowerAndCheckPermissions(
			pageAppPermissions,
			userAppPermissions
		)
	}

	const doesUserHaveNecessaryRole = (user: User): boolean => {
		if (gateLevel !== 'Validated') {
			return false
		}

		const pageRoles = page.elements.authenticationGateUserRoles.map(
			(a) => a.codename
		)

		return doesUserHaveSufficientRoles(pageRoles, user)
	}

	const checkUserRole = (
		item: Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>
	): boolean => {
		if (!('authenticationGateLevel' in item.elements)) {
			return true
		}
		const authLink = item as Tersed<AuthNavItemLinkContentItem>

		const itemGateLevel = authLink.elements.authenticationGateLevel[0]?.name
		if (
			!itemGateLevel ||
			itemGateLevel !== AuthenticationGateLevel.Validated
		) {
			return true
		}
		const userRoleRequired =
			authLink.elements.authenticationGateUserRoles.map(
				({codename}) => codename
			)
		const userRoles = userDetails.userRoles ?? []
		return areRolesSufficient(userRoleRequired, userRoles)
	}

	const checkAppPermission = (
		item: Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>
	): boolean => {
		if (!('authenticationGateLevel' in item.elements)) {
			return true
		}

		const authLink = item as Tersed<AuthNavItemLinkContentItem>

		const itemGateLevel = authLink.elements.authenticationGateLevel[0]?.name
		if (
			!itemGateLevel ||
			itemGateLevel !== AuthenticationGateLevel.Validated
		) {
			return true
		}

		const appPermissions =
			authLink.elements.authenticationGateApplicationPermissions.map(
				(perm) => perm.codename
			)
		const userAppPermissions = userDetails.appPermissions
		return loadTowerAndCheckPermissions(appPermissions, userAppPermissions)
	}

	const checkUserRoleForBlock = <TContentItem extends IContentItem>(
		item: Parameters<Block<TContentItem>>['0']['block']
	): boolean => {
		const allowedUserRoles = (
			item.elements.authenticationGateComponentUserRoles ?? []
		).map((role) => role.codename)
		const loggedInUserRoles = userDetails.userRoles ?? []
		return areRolesSufficient(allowedUserRoles, loggedInUserRoles)
	}

	const handleUserDetails = (
		uid: string,
		lightUser: boolean,
		profile?: Profile,
		user?: User
	): void => {
		if (profile && user) {
			const userHasNecessaryAppPermission =
				doesUserHaveNecessaryAppPermission(user.appPermission)
			const userHasNecessaryRole = doesUserHaveNecessaryRole(user)
			setUserDetails({
				id: uid,
				firstName: profile.firstName,
				lastName: profile.lastName,
				emailAddress: profile.email,
				telephone: profile.phones
					? profile.phones[0]?.number ?? null
					: null,
				practiceName: user.business?.name ?? null,
				practicePostcode: user.business?.zip ?? null,
				crmId:
					locale === 'en' || locale === Locales.UK
						? user.payTo ?? null
						: user.business?.crmId ?? null,
				light: lightUser,
				appPermissions: user.appPermission ?? null,
				userRoles: getUserRoles(user),
				salutation: user.salutation ?? '',
			})
			if (lightUser) {
				const newCookie = {
					id: uid,
					email: profile.email,
					light: true,
					v: userHasNecessaryAppPermission,
				}
				setCookie('cdc_user', btoa(JSON.stringify(newCookie)), {
					expires: getUserCookieExpirationDate(),
				})
			} else if (!getCookie('cdc_user')) {
				const newCookie = {
					id: uid,
					v: userHasNecessaryAppPermission,
				}
				setCookie('cdc_user', btoa(JSON.stringify(newCookie)), {
					expires: getUserCookieExpirationDate(),
				})
			}

			authenticateUser(true, userHasNecessaryAppPermission)
			authenticateUserRole(userHasNecessaryRole)
		} else {
			authenticateUser(false, false)
			setCookie('cdc_user', null)
		}
	}

	const getAppPermissions = (
		accountInfo: CdcUser
	): Promise<AppPermissions | null> => {
		const permissionsUrl = '/api/user/appPermissions'

		return fetch(`${permissionsUrl}?uid=${accountInfo.UID}`)
			.then((response) => {
				if (response.status === 200) {
					return response.json() as Promise<AppPermissions | null>
				}
				return null
			})
			.catch(() => {
				return null
			})
	}

	const accountInfoCallback = (accountInfo?: CdcUser): void => {
		if (accountInfo) {
			if (accountInfo.UID) {
				void getAppPermissions(accountInfo).then((appPermissions) => {
					const updatedAccountInfo = accountInfo
					if (appPermissions) {
						updatedAccountInfo.data.appPermission = {
							...accountInfo.data.appPermission,
							...appPermissions,
						}
					}

					handleUserDetails(
						updatedAccountInfo.UID,
						false,
						updatedAccountInfo.profile,
						updatedAccountInfo.data
					)
				})
			}

			handleUserDetails(
				accountInfo.UID,
				false,
				accountInfo.profile,
				accountInfo.data
			)
		}
	}

	useEffect(() => {
		if (ready) {
			window.gigya?.accounts.getAccountInfo({
				callback: accountInfoCallback,
				extraProfileFields: 'phones',
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Intentional
	}, [ready, router.asPath])

	return (
		<AuthContext.Provider
			value={{
				authenticated,
				validated,
				selfValidated,
				authLevel,
				userDetails,
				handleUserDetails,
				checkAppPermission,
				checkUserRole,
				checkUserRoleForBlock,
				ready,
			}}
		>
			<div data-testid="auth-provider">
				{globalConfig.elements.cdcKey ? (
					<Script
						id={`${globalConfig.elements.cdcKey}-gigya-page`}
						onReady={() => {
							window.onGigyaServiceReady = () => {
								setReady(true)
								window.gigya?.accounts.getAccountInfo({
									callback: accountInfoCallback,
									extraProfileFields: 'phones',
								})
							}
						}}
						src={`https://cdns.gigya.com/js/gigya.js?apikey=${globalConfig.elements.cdcKey}&lang=${locale}`}
					/>
				) : null}
				<input
					id="CDC-status"
					type="hidden"
					value={ready ? 'Ready' : 'Loading'}
				/>
				{shouldShowPage() && (
					<>
						{page.elements.authenticationGateLevel[0]?.name ===
							'1 - Self-Confirmation Required' &&
						globalConfig.elements.selfValidationPopup[0] &&
						!selfValidated ? (
							// @ts-expect-error -- No context is ok
							<SiteBannerSelfValidationBlock
								block={
									globalConfig.elements.selfValidationPopup[0]
								}
								globalConfig={globalConfig}
								page={page}
							/>
						) : null}
						{children}
					</>
				)}
			</div>
		</AuthContext.Provider>
	)
}
